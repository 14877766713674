window.ContentSelection = {

  form: '',
  lang: '',
  template: '',
  platform: '',
  mastertags: '',
  tags: [],
  localized_tags: [],

  init: function() {
    this.form = document.getElementById('new_youtube_presets_content') || document.getElementsByClassName('edit_youtube_presets_content')[0]
    this.getTemplate()
    this.getLang()
    this.getPlatform()

    fetch('/tags.json').then(response => response.json()).then(json => {
      ContentSelection.tags = json
      ContentSelection.getMastertags()
      Array.from(document.querySelectorAll('.tomselect-multiple-tags')).forEach((element, index) => {
        ContentSelection.addTomSelect(element, ContentSelection.tags)
      })
    })

    fetch('/localized_tags.json').then(response => response.json()).then(json => {
      ContentSelection.localized_tags = json
      Array.from(document.querySelectorAll('.tomselect-multiple-localized-tags')).forEach((element, index) => {
        ContentSelection.addTomSelect(element, ContentSelection.localized_tags)
      })
    })
  },

  addTomSelect: function(element, options) {
    new TomSelect(element, {
      delimiter: ',',
      valueField: 'id',
  		labelField: 'name',
  		searchField: 'name',
  		closeAfterSelect: true,
      options: options,
  		plugins: {
    		'clear_button':{
    			'title':'Remove all selected options',
    		}
    	},
    	onChange: function() {
      	const element = this.wrapper.parentElement.parentElement
      	ContentSelection.countEpisodes(element)
      	ContentSelection.displayDuplicate()
    	},
    	onInitialize: function()	{
      	ContentSelection.rewriteNum()
      	ContentSelection.countEpisodes(element.parentElement.parentElement)
    	}
    })
  },

  addEpisode: function() {
    const element = ContentSelection.template.cloneNode(true)
    Array.prototype.slice.call(element.querySelectorAll('.ts-wrapper')).map(el => el.remove())
    Array.prototype.slice.call(element.querySelectorAll('.ts-hidden-accessible')).map(el => el.classList.remove('ts-hidden-accessible'))
    document.querySelector('#episodes').append(element)
    const input = element.querySelector('.tomselect-multiple-tags')
    ContentSelection.addTomSelect(input, ContentSelection.tags)
    const inputL = element.querySelector('.tomselect-multiple-localized-tags')
    ContentSelection.addTomSelect(inputL, ContentSelection.localized_tags)
    ContentSelection.rewriteNum()
  },

  duplicateEpisode: function() {
    let temp = ContentSelection.template
    let episodes = Array.prototype.slice.call(document.querySelectorAll('.episode')).filter(function (item,index) { return item.style.display!="none" } )
    ContentSelection.template = episodes.pop()
    ContentSelection.addEpisode()
    let last = Array.prototype.slice.call(document.querySelectorAll('.episode')).filter(function (item,index) { return item.style.display!="none" } ).pop()
    last.querySelectorAll('select')[0].value = ContentSelection.template.querySelectorAll('select')[0].value
    last.querySelectorAll('select')[1].value = ContentSelection.template.querySelectorAll('select')[1].value
    ContentSelection.template = temp
  },

  removeEpisode: function(div) {
    let k = 0
    Array.from(document.querySelectorAll('.episode')).forEach((element, index) => {
      if (element.style.display != 'none') k++
    })
    if (k == 1) return
    div.parentNode.parentNode.style.display = 'none'
    setTimeout(function() { ContentSelection.rewriteNum() }, 10)
  },

  rewriteNum: function() {
    let k = 0
    Array.from(document.querySelectorAll('.episode')).forEach((element, index) => {
      element.querySelector('.kind').setAttribute('name', 'youtube_presets_content[items_attributes]['+index+'][kind]')
      element.querySelector('.priority_kind').setAttribute('name', 'youtube_presets_content[items_attributes]['+index+'][priority_kind]')
      element.querySelector('input.tag_ids').setAttribute('name', 'youtube_presets_content[items_attributes]['+index+'][tag_ids]')
      element.querySelector('input.localized_tag_ids').setAttribute('name', 'youtube_presets_content[items_attributes]['+index+'][localized_tag_ids]')
      if (element.style.display != 'none') {
        element.querySelector('.num').innerText = k+1
        k++
      } else {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'youtube_presets_content[items_attributes]['+index+'][_destroy]'
        input.value = true
        element.appendChild(input)
      }
    })
    this.displayDuplicate()
  },

  displayDuplicate: function() {
    let episodes = Array.prototype.slice.call(document.querySelectorAll('.episode')).filter(function (item,index) { return item.style.display!="none" } )
    let last = episodes.pop()
    let tag_ids = last.querySelector('.tag_ids').value
    let localized_tag_ids = last.querySelector('.localized_tag_ids').value
    document.querySelector('.duplicate-episode').style.display = (tag_ids != '' || localized_tag_ids != '') ? 'inline-block' : 'none'
  },

  kindChanged: function(select) {
    const element = select.parentElement.parentElement
    this.countEpisodes(element)
  },

  getTemplate: function() {
    const t = document.querySelector('.episode').cloneNode(true)
    t.children[1].children[0].value = ''
    t.children[2].children[0].value = ''
    t.children[3].children[0].value = ''
    this.template = t
  },

  getLang: function() {
    const selectLang = this.form.getAttribute('data-lang')
    this.lang = document.getElementById(selectLang) ? document.getElementById(selectLang).value : ''
  },

  getPlatform: function() {
    this.platform = this.form.getAttribute('data-platform')
  },

  getMastertags: function() {
    const inputMastertags = this.form.getAttribute('data-mastertags')
    this.mastertags = document.getElementById(inputMastertags) ? document.getElementById(inputMastertags).value : ''
    this.showMastertags()
  },

  showMastertags: function() {
    const element = document.getElementById('youtube-presets-contents-mastertags')
    const label = document.createElement('label')

    if (this.mastertags) {
      label.classList.add('form-label')
      label.classList.add('w-100')
      label.innerHTML = 'Master Tags'
      element.append(label)
    };

    this.mastertags.split(',').forEach(val => {
      if (ContentSelection.tags.find(x => x.id == val)) {
        const tag = ContentSelection.tags.find(x => x.id == val).name
        const div = document.createElement('div')
        div.classList.add('badge')
        div.classList.add('bg-primary-soft')
        div.innerHTML = tag
        element.append(div)
      }
    })
  },

  countEpisodes: function(element) {

    const tags = []
    const localized_tags = []

    this.mastertags.split(',').forEach(val => {
      if (ContentSelection.tags.find(x => x.id == val)) {
        tags.push(ContentSelection.tags.find(x => x.id == val).name)
      }
    })

    element.querySelector('.tomselect-multiple-tags').value.split(',').forEach(val => {
      if (ContentSelection.tags.find(x => x.id == val)) {
        tags.push(ContentSelection.tags.find(x => x.id == val).name)
      }
    })

    element.querySelector('.tomselect-multiple-localized-tags').value.split(',').forEach(val => {
      if (ContentSelection.localized_tags.find(x => x.id == val)) {
        localized_tags.push(ContentSelection.localized_tags.find(x => x.id == val).name)
      }
    })

    const kind = element.querySelector('.kind').value
    const params = 'episode_versions[episode_tagged_with]='+tags+'&episode_versions[episode_localized_tagged_with]='+localized_tags+'&episode_versions[language_code]='+this.lang+'&episode_versions[platform_stock_kind][platform]='+this.platform+'&episode_versions[platform_stock_kind][kind]='+kind

    element.querySelector('.nb').innerText = '...'
    fetch('/episode_versions.json?'+params).then(response => response.json()).then(data => {
      element.querySelector('.nb').innerText = Intl.NumberFormat('en-US', { notation: 'compact' }).format(data.meta.total_count)
    })
  }
}
